import api from '@http/api';

export interface IOrigemEspecifica {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IOrigemMacro {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IMotivo {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IMotivoResult {
  result: IMotivo[];
}

export interface IOrigemEspecificaResult {
  result: IOrigemEspecifica[];
}

export interface IFormaContribuicao {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface ITipoCorrespondecia {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IContatoOffline {
  contato_offline_id: string;
  bairro: string;
  cidade_id: string;
  estado_id: string;
  cep: string;
  logradouro: string;
  endereco: string;
  complemento: string;
  Distrito: string;
  status_ativo: string;
}

export interface IContatoOnline {
  contato_online_descricao: string;
}

export interface IUpdatePerfil {
  id: string;
  motivo_cadastro_id: string;
  origem_especifica_id: string;
  forma_contribuicao_id: string;
  tipo_correspondencia_id: string;
  status_ativo: number;
  contato_offline: IContatoOffline;
  telefone_fixo: IContatoOnline;
  telefone_comercial: IContatoOnline;
  celular: IContatoOnline;
  whatsapp: IContatoOnline;
  email: IContatoOnline;
}

export interface IPerfil2 {
  id: number;
  descricao: string;
  status_ativo: boolean;
}

export interface IRequestCreatePerfil {
  pessoa_id: number;
  nome: string;
  nome_fantasia:string;
  motivo_cadastro_id: number;
  origem_especifica_id: number;
  origem_macro_id: number;
  forma_contribuicao_id: number;
  tipo_correspondencia_id: number;
  tipo_perfil_id: number;
  status_id: number;
  status_ativo: number;
  valor_padrao_doacao: number;
  recorrencia_meses: number;
  contato_offline?: {
    bairro: string;
    cidade_id: number;
    cep: string;
    logradouro: string;
    endereco: string;
    complemento: string;
    status_ativo: number;
    Distrito: string;
  };
  telefone_fixo?: {
    contato_online_descricao: string;
    contato_online_contato_primario: number;
    status_ativo: number;
  };
  telefone_comercial?: {
    contato_online_descricao: string;
    contato_online_contato_primario: number;
    status_ativo: number;
  };
  celular?: {
    contato_online_descricao: string;
    contato_online_contato_primario: number;
    status_ativo: number;
  };
  whatsapp?: {
    contato_online_descricao: string;
    contato_online_contato_primario: number;
    status_ativo: number;
  };
  email?: {
    contato_online_descricao: string;
    contato_online_contato_primario: number;
    status_ativo: number;
  };
  debito_automatico?: {
    id_convenio: number;
    agencia_numero: string;
    agencia_digito: string;
    conta_numero: string;
    conta_digito: string;
    operacao: string;
    valor: number;
    dia_vencimento: string;
    recorrente: number;
    status_ativo: number;
    id_perfil: number;
  };
  debito_conta_energia?: {
    id_convenio?: number;
    conta_contrato_numero: string;
    valor: number;
    recorrencia: number;
    status_ativo: true;
    id_perfil?: number;
  };
}

export interface IConvenio {
  id: number;
  numero_convenio: string;
  nome_cedente: number | string | null;
  numero_agencia: number | string | null;
  digito_agencia: number | string | null;
  numero_conta: number | string | null;
  digito_cedente: number | string | null;
  id_banco: number;
  descricao: string;
}

export interface IOperacao {
  id: number;
  descricao: string;
  codigo_operacao: string;
}

class ComponentPerfilServices {
  public async loadOrigemEspecifica() {
    return await api
      .get<IOrigemEspecifica[]>('/api/OrigemEspecifica/GetOrigensEspecificas')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadOrigemMacro() {
    return await api
      .get<IOrigemMacro[]>('/api/OrigemMacro/GetOrigensMacro')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadMotivos() {
    return await api
      .get<IMotivo[]>('/api/MotivoCadastro/GetMotivosCadastros')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetTipoMotivoByOrigemEspecifica(origem_especifica_id: string | number) {
    return await api
      .get<IMotivoResult>(
        `/api/MotivoCadastro/GetMotivoByOrigemEspecificaId?idOrigemEspecifica=${origem_especifica_id}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }

  public async GetTipoOrigemEspecificaByOrigemMacro(origem_macro_id: string | number) {
    return await api
      .get<IOrigemEspecificaResult>(
        `api/OrigemEspecifica/GetOrigemEspecificaByOrigemMacroId?idOrigemMacro=${origem_macro_id}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }

  public async loadFormaContribuicao() {
    return await api
      .get<IFormaContribuicao[]>('/api/FormaContribuicao/GetFormasContribuicao')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadTiposCorrespondecia() {
    return await api
      .get<ITipoCorrespondecia[]>(
        '/api/TipoCorrespondencia/GetTiposCorrespondecia',
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async UpdatePerfil({
    contato_offline,
    forma_contribuicao_id,
    id,
    motivo_cadastro_id,
    origem_especifica_id,
    status_ativo,
    tipo_correspondencia_id,
    celular,
    email,
    telefone_comercial,
    telefone_fixo,
    whatsapp,
  }: IUpdatePerfil) {
    return await api
      .put('/api/Perfil/UpdatePerfilById', {
        contato_offline,
        forma_contribuicao_id,
        id,
        motivo_cadastro_id,
        nome: '',
        origem_especifica_id,
        status_ativo,
        tipo_correspondencia_id,
        celular,
        email,
        telefone_comercial,
        telefone_fixo,
        whatsapp,
      })

      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async GetTiposPerfil() {
    return await api
      .get<IPerfil2[]>('api/TipoPerfil/GetTiposPerfil')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async CreatePerfil(data: IRequestCreatePerfil) {
    return await api
      .post('api/Perfil/CreatePerfil', data)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetConvenios(id: number | string) {
    return await api
      .get<IConvenio[]>(`api/Financeiro/GetFinanceiroConvenios`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetOperacoes(id: number | string) {
    return await api
      .get<IOperacao[]>(`api/Financeiro/GetFinanceiroOperacoes?bancoId=${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }
}

export default ComponentPerfilServices;
