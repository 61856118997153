import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Checkbox, Grid, IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import ButtonWithIcon from '@components/Shared/Buttons/ButtonWithIcon';
import Modal from '@src/components/Shared/Modal/ModalPure';
import ApproveSolicitacao from '@components/Shared/Solicitacao/approveSolicitacao';
import RecuseSolicitacao from '@components/Shared/Solicitacao/recuseSolicitacao';
import { mdiArrowTopRight, mdiArrowBottomRight } from '@mdi/js';
//import Icon from '@mdi/react';

import CountDown from '@components/Shared/CountDown';
import TableCollapsible from '@components/Shared/Tables/TableColapseNoPaginationWithSelection';
import { TitlePage } from '@src/@styles/styles.global';
import { useAuth } from '@hooks/AuthenticateContext';

import InvisibleContent from '@components/Shared/InvisibleContent';

import Services, {
  ISolicitacoes,
  ITipoSolicitacao,
  ISolicitacaoResultTable,
} from './services';

import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Collapse from '@material-ui/core/Collapse';

import { Form, Select as SelectAntd } from 'antd';

import {
  StyledTableCell,
  StyledTableRow,
} from '@src/components/Shared/Tables/TableCollapsible/styles';

const columnsLabel = {
  Data: 'Solicitado em',
  IDCadastro: 'ID Cadastro',
  NomeCadastro: 'Nome',
  Campanha: 'Campanha',
  Perfil: 'Perfil',
  SolicitadoPor: 'Solicitado por',
  Banco: 'Banco',
  Observacao: [
    {
      coluna: 'string',
      valor: 'string',
    },
  ],

};

interface ITest {
  Data: string;
  TipoSolicitacao: string;
  valor: string;
  dataVencimento: string;
  solicitadoPor: string;
  idStatusSolicitacao: number;
  StatusSolicitacao: string;
  Observacao: [
    {
      coluna: string;
      valor: string;
    },
  ];
}

interface IProps {
  idPerfil: number;
  functionReload?: Function;
  idSolicitacao: number;
}

const ListSolicitacao: React.FC<IProps> = ({
  idPerfil,
  functionReload,
  idSolicitacao,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ISolicitacoes[]>([]);
  const [tipoSolicitacaoSelected, setTipoSolicitacaoSelected] = useState(0);
  const [tipoSolicitacao, setTipoSolicitacao] = useState<ITipoSolicitacao[]>(
    [] || null,
  );
  const [openModalApproveSolicitacao, setOpenModalApproveSolicitacao] =
    useState(false);
  const [openModalRecuseSolicitacao, setOpenModalRecuseSolicitacao] =
    useState(false);

  const [dadosSelecionadas, setDadosSelecionadas] = useState<string[]>([]);

  const [solicitacaoSelecionada, setSolicitacaoSelecionada] =
  useState('');


  async function loadCampanhas() {
    const services = new Services();

    const { error, response } = await services.getTipoSolicitacao();
    if (!error && response?.length) {
      setTipoSolicitacao(response);
      setTipoSolicitacaoSelected(response[0].id);
      //loadTipoSolicitacao(response[0].id);
    }
  }

  const loadTipoSolicitacao = async (id_tipo_solicitacao: number) => {
    setLoading(true);
    const services = new Services();

    const { error, response } = await services.loadTipoSolicitacao({
      id_tipo_solicitacao: id_tipo_solicitacao,
    });

    if (!error && response) {
      setData(response);
    }

    setLoading(false);
  };

  const finishRecuseSolicitacao = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalRecuseSolicitacao(false);
  };

  const finishApproveSolicitacao = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalApproveSolicitacao(false);
  };

  const selectedDatas = (value: ISolicitacaoResultTable) => {
    if (dadosSelecionadas.filter(x => x === value.IDSolicitacao).length <= 0) {
      setDadosSelecionadas([...dadosSelecionadas, value.IDSolicitacao]);
    } else {
      const newArray = dadosSelecionadas.filter(function (item) {
        return item !== value.IDSolicitacao;
      });

      setDadosSelecionadas(newArray);
    }
  };


  useEffect(() => {
    loadCampanhas();
    window.document.title = 'CRM - Lista de Solicitações';
  }, []);

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

    const filterCheck = (id: any) => {
      const tipo = tipoSolicitacao.filter(x => x.id === id);

      if (tipo.length > 0) setSolicitacaoSelecionada(tipo[0].descricao);
    };




  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <TitlePage style={{ margin: 5 }}>Solicitações</TitlePage>
      </Grid>

      <Grid
        container
        alignItems="center"
        item
        md={12}
        xs={12}
        style={{ margin: 5 }}
      >
        <Grid item md={4} xs={12}>
          <Form.Item name={'tipo'}>
            <SelectAntd
              style={{ width: '80%' }}
              placeholder="Tipo de solicitação"
              onChange={(e: number) => {
                setTipoSolicitacaoSelected(e);
                loadTipoSolicitacao(e);
              }}
              value={tipoSolicitacao[0] ? tipoSolicitacaoSelected : 0}
              onSelect={(e: any) => filterCheck(e)}
            >
              {tipoSolicitacao.map((option, index) => (
                <SelectAntd.Option key={option.id} value={option.id}>
                  {option.descricao}
                </SelectAntd.Option>
              ))}
            </SelectAntd>
          </Form.Item>
        </Grid>

        <Modal
          title="Recusar solicitações"
          size="medio"
          openModal={openModalRecuseSolicitacao}
          controlModal={(value: boolean) =>
            setOpenModalRecuseSolicitacao(value)
          }
        >
          <RecuseSolicitacao
            perfil_id={idPerfil}
            finishCreate={finishRecuseSolicitacao}
            ids_solicitacao={dadosSelecionadas}
          />
        </Modal>

        <Grid item md={2} xs={4} style={{ margin: 5 }}>
          <ButtonWithIcon
            color="tertiary"
            fullWidth
            disabled={dadosSelecionadas.length <= 0}
            onClick={() => setOpenModalRecuseSolicitacao(true)}
            titleButton="Recusar solicitações "
            //</Grid>Icon={() => (
              //<Icon path={mdiArrowBottomRight} size={1} color="White" />
            //)}
          ></ButtonWithIcon>
        </Grid>

        <Modal
          title="Aprovar solicitações"
          size="medio"
          openModal={openModalApproveSolicitacao}
          controlModal={(value: boolean) =>
            setOpenModalApproveSolicitacao(value)
          }
        >
          <ApproveSolicitacao
            perfil_id={idPerfil}
            finishCreate={finishApproveSolicitacao}
            id_solicitacao={dadosSelecionadas}
          />
        </Modal>

        <InvisibleContent visible={solicitacaoSelecionada.includes('BOLETO')}>

        <Grid item md={2} xs={4} style={{ margin: 5 }}>
          <ButtonWithIcon
            color="secondary"
            fullWidth
            disabled
            onClick={() => setOpenModalApproveSolicitacao(true)}
            titleButton="Aprovar solicitações"
            //Icon={() => <Icon path={mdiArrowTopRight} size={1} color="White" />}
          ></ButtonWithIcon>
        </Grid>
        </InvisibleContent>

        <InvisibleContent visible={!solicitacaoSelecionada.includes('BOLETO')}>

        <Grid item md={2} xs={4} style={{ margin: 5 }}>
          <ButtonWithIcon
            color="secondary"
            fullWidth
            disabled={dadosSelecionadas.length <= 0}
            onClick={() => setOpenModalApproveSolicitacao(true)}
            titleButton="Aprovar solicitações"
            //Icon={() => <Icon path={mdiArrowTopRight} size={1} color="White" />}
          ></ButtonWithIcon>
        </Grid>
        </InvisibleContent>

      </Grid>

      <Grid item lg={12} xs={12}>
        <CountDown
          minutesDefault={1}
          changeFunction={loadTipoSolicitacao}
          title="Tempo para atualização dos dados"
        />
      </Grid>
      <Grid item lg={10} xs={12}>
        <TableCollapsible
          Selected={(e: ISolicitacaoResultTable) => (
            <Grid container justifyContent="center">
              <Checkbox
                onChange={() => selectedDatas(e)}
                checked={
                  dadosSelecionadas.filter(x => x === e.IDSolicitacao).length >
                  0
                }
              />
            </Grid>
          )}
          loading={loading}
          columns={[columnsLabel]}
          FieldsBold={['Perfil']}
          data={data}
          keys={[
            'Data',
            'IDCadastro',
            'NomeCadastro',
            'Perfil',
            'Campanha',
            'SolicitadoPor',
            'Banco',
          ]}
          ActionHeader="Detalhes"
          Actions={[
            {
              Button: (row: ITest) => {
                const [open, setOpen] = React.useState(false);
                return (
                  <TableContainer>
                    <TableRow>
                      <StyledTableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(!open)}
                        >
                          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  </TableContainer>
                );
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default ListSolicitacao;
